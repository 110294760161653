<template>
  <tr :class="cssClass">
    <td
      v-for="(column, index) in headers"
      :key="`${index}.${column.value}`"
      :class="column.align ? `text-${column.align}` : ''"
      class="w-max-200"
    >
      <v-btn v-if="column.value == 'reorder'" class="handler" icon text>
        <icon-menu size="1.5em" />
      </v-btn>

      <v-select
        v-else-if="column.value === 'is_positive'"
        v-model="item.is_positive"
        :items="arIsPositiveOptions"
        :menu-props="{ offsetY: true }"
        dense
        hide-details
        outlined
      />

      <accounting-credit-debit-select
        v-else-if="column.value === 'debit'"
        v-model="item.debit"
        :disabled="!!item.credit"
      />

      <accounting-credit-debit-select
        v-else-if="column.value === 'credit'"
        v-model="item.credit"
        :disabled="!!item.debit"
      />

      <template
        v-else-if="
          column.value &&
          isColumn(column.value) &&
          enabledColumn > getColumnIndex(column.value)
        "
      >
        <accounting-column-field
          :key="`${sKey}.${getColumnIndex(column.value)}`"
          :column="getColumnIndex(column.value)"
          :value="getColumn(column.value)"
          @input="onUpdateColumn($event, getColumnIndex(column.value))"
          @remove="onResetColumn"
        />
      </template>

      <module-actions
        v-else-if="column.value === 'actions'"
        cannot-edit
        @delete="onDelete"
      ></module-actions>

      <!--      <span v-else> {{ row }} - {{ index }} - {{ column.value }} </span>-->
    </td>
  </tr>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type {
  AccountingEntryColumnData,
  AccountingEntryPositionData,
} from "@planetadeleste/vue-mc-gw";
import {
  forEach,
  get,
  has,
  isNil,
  isUndefined,
  last,
  set,
  sortBy,
  startsWith,
  uniqueId,
} from "lodash";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import AccountingCreditDebitSelect from "@/modules/accountingentries/components/AccountingCreditDebitSelect.vue";
import AccountingDescriptionSelect from "@/modules/accountingentries/components/AccountingDescriptionSelect.vue";
import AccountingBookSelect from "@/modules/accountingentries/components/AccountingBookSelect.vue";
import AccountingColumnField from "@/modules/accountingentries/components/AccountingColumnField.vue";
import { number } from "mathjs";
import { toNumeric } from "@/components/functions/toNumeric";

@Component({
  components: {
    AccountingColumnField,
    AccountingBookSelect,
    AccountingDescriptionSelect,
    AccountingCreditDebitSelect,
  },
})
export default class AccountingEntryPositionItem extends Vue {
  @Prop(Object) readonly item!: AccountingEntryPositionData;
  @Prop(Array) readonly headers!: DataTableHeader[];
  @Prop(Number) readonly row!: number;
  @Prop(String) readonly book!: string;
  @Prop(String) readonly config!: string;

  currentKey: string = uniqueId();

  get arColumns(): Partial<AccountingEntryColumnData>[] {
    return sortBy(get(this.item, "columns", []), "sort_order");
  }

  set arColumns(arValue: Partial<AccountingEntryColumnData>[]) {
    set(this.item, "columns", arValue);
  }

  get arIsPositiveOptions() {
    return [
      { text: "+", value: true },
      { text: "-", value: false },
    ];
  }

  get validColumns(): boolean {
    if (!this.arColumns.length) {
      return false;
    }

    let valid = true;

    forEach(
      this.arColumns,
      (obItem: Partial<AccountingEntryColumnData>, index: number) => {
        if (index > 0 && index === this.arColumns.length - 1) {
          return;
        }

        if (!obItem.field && !obItem.code) {
          valid = false;
        }
      }
    );

    return valid;
  }

  get valid() {
    let isValid = true;

    if (
      !this.validColumns ||
      (this.item.debit && this.item.credit) ||
      (!this.item.credit && !this.item.debit) ||
      isNil(this.item.is_positive)
    ) {
      isValid = false;
    }

    return isValid;
  }

  get sConfig(): string {
    return get(this.item, "config", "");
  }

  get sKey() {
    const arParts: (string | number)[] = [this.currentKey];

    if (this.item.id) {
      arParts.push(this.item.id);
    }

    arParts.push(uniqueId("aep"));
    arParts.push(this.row);

    return arParts.join(".");
  }

  get cssClass() {
    return {
      "red lighten-5": !this.valid,
    };
  }

  get enabledColumn(): number {
    return this.arColumns.length || 1;
  }

  addUpdateColumn(obData?: Partial<AccountingEntryColumnData>, index?: number) {
    const arColumnList = [...this.arColumns];

    if (!obData) {
      obData = {
        item_type: "code",
        sort_order: this.arColumns.length + 1,
      };
    }

    if (isUndefined(index)) {
      const latestColumn = last(arColumnList);
      if (!has(latestColumn, "field") && !has(latestColumn, "code")) {
        return;
      }

      arColumnList.push(obData);
    } else {
      arColumnList.splice(index, 1, obData);
    }

    set(this.item, "columns", arColumnList);
    this.currentKey = uniqueId();
  }

  onResetColumn(index?: number) {
    if (isUndefined(index)) {
      index = this.arColumns.length - 1;
    }

    const arColumns = [...this.arColumns];
    arColumns.splice(index, 1);
    set(this.item, "columns", arColumns);

    this.addUpdateColumn();
  }

  isColumn(sValue: string) {
    return startsWith(sValue, "column");
  }

  getColumnIndex(sValue: string) {
    if (!this.isColumn(sValue)) {
      return undefined;
    }

    return number(toNumeric(sValue)) - 1;
  }

  getColumn(sValue: string): Partial<AccountingEntryColumnData> | undefined {
    const iIndex = this.getColumnIndex(sValue);

    return isNil(iIndex) || !this.enabledColumn
      ? undefined
      : this.arColumns[iIndex];
  }

  onUpdateColumn(obData: Partial<AccountingEntryColumnData>, index?: number) {
    if (isUndefined(index)) {
      return;
    }

    this.addUpdateColumn(obData, index);

    if (this.enabledColumn < 6) {
      this.addUpdateColumn();
    }
  }

  async onDelete() {
    const bRemove = await this.$confirm(
      this.$t("ask.remove.record") as string,
      { color: "warning" }
    );
    if (bRemove) {
      this.$emit("delete", this.row);
    }
  }

  mounted() {
    this.addUpdateColumn();
  }
}
</script>
